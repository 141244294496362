import { Box, Text, BoxProps, IconButton, Button } from "@chakra-ui/react";
import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from "react-icons/fi";

interface Props extends BoxProps {
  total: number;
  page: number;
  itemsPerPage: number;
  handleChangePage: (pageNumber: number) => void;
  disabled: boolean;
}

const Pagination = ({
  total,
  page,
  itemsPerPage,
  handleChangePage,
  disabled,
  ...rest
}: Props) => {
  const totalPages = Math.max(1, Math.ceil(total / itemsPerPage));

  const offsetNumber = page * itemsPerPage + 1;
  const offsetNumberEnd = Math.min((page + 1) * itemsPerPage, total);

  return (
    <Box display="flex" alignItems="center" {...rest}>
      <Text fontSize="0.95em">
        Mostrando {offsetNumber}-{offsetNumberEnd} de {total}
      </Text>
      <Box display="flex" alignItems="center">
        <IconButton
          aria-label="Primera Página"
          variant="ghost"
          icon={<FiChevronsLeft />}
          disabled={page <= 0 || disabled}
          onClick={() => handleChangePage(0)}
        />

        <IconButton
          aria-label="Anterior"
          variant="ghost"
          icon={<FiChevronLeft />}
          disabled={page <= 0 || disabled}
          onClick={() => handleChangePage(page - 1)}
        />

        <Button variant="ghost">{page + 1}</Button>

        <IconButton
          aria-label="Siguiente"
          variant="ghost"
          icon={<FiChevronRight />}
          disabled={page >= totalPages - 1 || disabled}
          onClick={() => handleChangePage(page + 1)}
        />

        <IconButton
          aria-label="Última Página"
          variant="ghost"
          icon={<FiChevronsRight />}
          disabled={page >= totalPages - 1 || disabled}
          onClick={() => handleChangePage(totalPages - 1)}
        />
      </Box>
    </Box>
  );
};

export default Pagination;
