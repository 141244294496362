import { useState, useEffect } from "react";
import {
  Box,
  Text,
  Button,
  Heading,
  useColorModeValue,
  Icon,
  Divider,
  Spinner,
  Alert,
  AlertIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { FiPlus, FiInfo } from "react-icons/fi";
import FiltersForm, {
  FiltersFormValues,
} from "../components/users/FiltersForm";
import Pagination from "../components/Pagination";
import FormNewUser from "../components/users/FormNewUser";
import { fetchAxios, formatHandledErrors } from "../axiosConfig";
import { useAuth } from "../hooks/useAuth";
import SubUserCard from "../components/users/SubUserCard";
import FormAssignAdmin from "../components/users/FormAssignAdmin";

interface UserProps {
  idUsers: number;
  numberID: string;
  name: string;
  phone?: string;
  imageUrl?: string;
  appName: string;
  status: number;
  email: string;
  license?: any;
}

const REGISTERS_LIMIT = 10;

const INITIAL_FILTER_VALUES = {
  clientIdFilter: "none",
  appName: "none",
  status: "none",
  nameOrDni: "",
};

const SubUser = () => {
  const { authState } = useAuth();

  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState(INITIAL_FILTER_VALUES);
  const [typeUsers, setTypeUsers] = useState("admin");
  const [total, setTotal] = useState(0);
  const [usersList, setUsersList] = useState<any>([]);

  const [isLoading, setIsLoading] = useState(true);
  const [getUsersErrorMsg, setGetUsersErrorMsg] = useState<string | null>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: isOpenModalStatus,
    onOpen: onOpenModalStatus,
    onClose: onCloseModalStatus,
  } = useDisclosure();

  const handleSendFiltersForm = (values: FiltersFormValues) => {
    setFilters({
      clientIdFilter: values.license,
      appName: values.app,
      status: values.status,
      nameOrDni: values.search,
    });
  };

  const handleResetForm = () => setFilters(INITIAL_FILTER_VALUES);

  const handleGetUsersList = async (type: string) => {
    setIsLoading(true);
    try {
      const role = authState.user?.clientType === "Bartik" ? "admin" : "user";
      const uri = `/user/get-user/${authState.user?.id}?page=${page}&limit=${REGISTERS_LIMIT}&role=${role}&type=${type}`;

      const axiosResponse = await fetchAxios(uri, {
        method: "POST",
        headers: { authorization: `Bearer ${authState.token}` },
        data: filters,
      });

      const { count, rows } = axiosResponse.data.dataInfo;

      setUsersList([]);
      setUsersList(rows);
      setTotal(count);

      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      const formatedError = formatHandledErrors(error);
      setGetUsersErrorMsg(formatedError);
    }
  };

  const handleAddNewUser = (newUser: any) => {
    setUsersList((prevState: any) => [newUser, ...prevState]);
  };

  const handleUpdateUserStatus = (numberId: string, newStatus: number) => {
    setUsersList((prevState: any) =>
      prevState.map((item: any) => {
        if (item.numberID === numberId) {
          return {
            ...item,
            status: newStatus,
          };
        }

        return item;
      })
    );
  };

  const handleChangePage = (numberPage: number) => setPage(numberPage);

  const handleTypeUsers = () => {
    if (typeUsers === "admin") {
      setTypeUsers("Operador");
      handleGetUsersList("Operador");
    } else {
      setTypeUsers("admin");
      handleGetUsersList("admin");
    }
  };

  const changeUpdateUser = (
    id: any,
    numberId: string,
    name: string,
    email: string,
    phone: string
  ) => {
    setUsersList((prevState: any) =>
      prevState.map((item: any) => {
        if (item.idUsers === id) {
          return {
            ...item,
            numberID: numberId,
            name: name,
            email: email,
            phone: phone,
          };
        }

        return item;
      })
    );
  };

  const changeUpdateUserLicense = (id: any, data: any) => {
    setUsersList((prevState: any) =>
      prevState.map((item: any) => {
        if (item.idUsers === id) {
          return {
            ...item,
            license: [data, ...item.license],
          };
        }

        return item;
      })
    );
  };

  const changeUpdateUserImagen = (id: any, image: any) => {
    setUsersList((prevState: any) =>
      prevState.map((item: any) => {
        if (item.idUsers === id) {
          return {
            ...item,
            imageUrl: image,
          };
        }

        return item;
      })
    );
  };

  useEffect(() => {
    handleGetUsersList(typeUsers);
  }, [filters, page]);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        bg={useColorModeValue("white", "gray.700")}
        padding="1em"
        borderRadius="10px"
        sx={{
          "@media (max-width: 600px)": {
            flexDir: "column",
          },
        }}
      >
        <Heading
          sx={{
            "@media (max-width: 600px)": {
              textAlign: "center",
              marginBottom: 15,
            },
          }}
        >
          Usuarios {typeUsers === "admin" ? "Administradores" : "Operarios"}{" "}
        </Heading>

        <Box>
          <Button
            colorScheme="greenDark"
            leftIcon={<FiPlus />}
            onClick={onOpen}
            ml="1rem"
            mr="1rem"
          >
            Nuevo Usuario
          </Button>

          {authState.user?.clientType === "Bartik" && (
            <Button
              colorScheme="greenDark"
              leftIcon={<FiPlus />}
              onClick={onOpenModalStatus}
              ml="1rem"
              mr="1rem"
            >
              Asignar Administrador
            </Button>
          )}
        </Box>
      </Box>

      <Box
        bg={useColorModeValue("white", "gray.700")}
        padding="1em"
        borderRadius="10px"
        mt="1em"
      >
        <Text verticalAlign="center">
          <Icon as={FiInfo} color="blue.500" mr="0.5em" display="inline" />
          Gestiona los usuarios de las aplicaciones de
          <Text as="span" mx="0.2em" fontWeight="bold">
            Visitik Admin
          </Text>{" "}
          y
          <Text as="span" mx="0.2em" fontWeight="bold">
            Visitik Operarios
          </Text>
          asociados a tus licencias
        </Text>
        <Text verticalAlign="center">
          <Icon as={FiInfo} color="blue.500" mr="0.5em" display="inline" />
          Una vez creado el usuario se enviara un Email al correo registrado con
          la contraseña temporal, por favor asegurate de escribir un correo
          válido.
        </Text>

        <Divider my="1em" />

        <Box width="full" display="flex" flexDirection="column">
          <FiltersForm
            onSubmit={handleSendFiltersForm}
            resetForm={handleResetForm}
          />

          <Divider my="1em" />

          <Box
            width="full"
            display="flex"
            alignItems="center"
            position="relative"
            mb="1em"
            sx={{
              "@media (max-width: 600px)": {
                flexDir: "column-reverse",
              },
            }}
          >
            <Box
              height="full"
              position="absolute"
              top="0"
              left="10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {isLoading && (
                <>
                  <Spinner mr="0.5em" /> Cargando
                </>
              )}
            </Box>
            <Box
              display="flex"
              alignItems="center"
              float="left"
              bg={useColorModeValue("white", "gray.700")}
              borderRadius="10px"
            >
              <>
                <Button
                  ml="auto"
                  mr="1em"
                  colorScheme="greenDark"
                  // leftIcon={<FiPlus />}
                  onClick={handleTypeUsers}
                >
                  {typeUsers === "admin" ? "Operarios" : "Administradores"}
                </Button>
              </>
            </Box>

            <Box width={"100%"}>
              <Pagination
                total={total}
                page={page}
                itemsPerPage={REGISTERS_LIMIT}
                handleChangePage={handleChangePage}
                width="50%"
                float="right"
                justifyContent="flex-end"
                sx={{
                  "@media (max-width: 600px)": {
                    width: "100%",
                  },
                }}
                disabled={isLoading}
              />
            </Box>
          </Box>

          {getUsersErrorMsg && (
            <Alert status="error">
              <AlertIcon />
              {getUsersErrorMsg}
            </Alert>
          )}

          {usersList.map((item: UserProps) => (
            <SubUserCard
              key={item.idUsers}
              idUsers={item.idUsers}
              numberId={item.numberID}
              name={item.name}
              image={item.imageUrl}
              application={item.appName}
              status={item.status}
              email={item.email}
              license={item.license}
              contactPhone={item.phone}
              updateStatus={handleUpdateUserStatus}
              changeUpdateUser={changeUpdateUser}
              changeUpdateUserImagen={changeUpdateUserImagen}
            />
          ))}
        </Box>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Registrar un nuevo usuario</ModalHeader>
          <ModalCloseButton />
          <FormNewUser
            closeModal={onClose}
            handleUpdateUsersList={handleAddNewUser}
          />
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenModalStatus} onClose={onCloseModalStatus}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Asignar Administrador</ModalHeader>

          <FormAssignAdmin
            closeModal={onCloseModalStatus}
            listAdmin={usersList}
            changeUpdateUserLicense={changeUpdateUserLicense}
          />

          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </>
  );
};

export default SubUser;
