import { InfoIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Heading,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { fetchAxios, formatHandledErrors } from "../axiosConfig";
import NewMessage from "../components/Message/NewMessage";
import Pagination from "../components/Pagination";
import ReportsCard from "../components/ReportsCard";
import { useAuth } from "../hooks/useAuth";

const REGISTERS_LIMIT = 10;

const SendRepost = () => {
  const { onOpen, isOpen, onClose } = useDisclosure();

  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [getReportError, setGetReportError] = useState("");
  const [getClientsError, setGetClientsError] = useState("");
  const [informe, setInforme] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [filterClients, setFilterClients] = useState("");

  const { authState } = useAuth();

  const handleChangePage = (numberPage: number) => setPage(numberPage);

  const getClients = async () => {
    try {
      const uri = `/client/get_clients/${authState.user?.id}?rol=user`;
      const axiosResponse = await fetchAxios(uri, {
        headers: { authorization: `Bearer ${authState.token}` },
      });

      const { rows } = axiosResponse.data.dataInfo;

      setClients(rows);
    } catch (error: any) {
      const formatedError = formatHandledErrors(error);
      setGetClientsError(formatedError);
    }
  };

  const getSendReport = async () => {
    setLoading(true);
    try {
      const filter = filterClients === "" ? "all" : "clients";
      const uri = `/repots/get-repost-send/${authState.user?.id}?page=${page}&limit=${REGISTERS_LIMIT}&filter=${filter}&type=Informes&clients=${filterClients}`;
      const axiosResponse = await fetchAxios(uri, {
        headers: { authorization: `Bearer ${authState.token}` },
      });

      const { count, repostResponse } = axiosResponse.data.dataInfo;

      setInforme(repostResponse);
      setLoading(false);
      setTotal(count);
    } catch (error: any) {
      setLoading(false);
      const formatedError = formatHandledErrors(
        error.response.data.dataInfo.message
          ? error.response.data.dataInfo.message
          : error
      );
      setGetReportError(formatedError);
    }
  };

  useEffect(() => {
    getSendReport();
    getClients();
  }, [filterClients]);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        bg={useColorModeValue("white", "gray.700")}
        padding="1em"
        borderRadius="10px"
        sx={{
          "@media (max-width: 600px)": {
            flexDir: "column",
          },
        }}
      >
        <Heading>Informes</Heading>

        <Button
          colorScheme="greenDark"
          sx={{
            "@media (max-width: 600px)": {
              mt: 5,
            },
          }}
          leftIcon={<FiPlus />}
          onClick={() => onOpen()}
        >
          Enviar nuevo informe
        </Button>
      </Box>

      <Box
        bg={useColorModeValue("white", "gray.700")}
        padding="1em"
        borderRadius="10px"
        mt="1em"
      >
        <Box width="full" display="flex" flexDirection="column">
          <Box
            display="flex"
            justifyItems="center"
            width="full"
            position="relative"
            mb="1em"
            sx={{
              "@media (max-width: 600px)": {
                flexDir: "column-reverse",
              },
            }}
          >
            <Box
              height="full"
              position="absolute"
              top="0"
              left="10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            ></Box>

            <Select onChange={(e) => setFilterClients(e.target.value)}>
              <option value="">Clientes</option>
              {clients.map((item: any) => (
                <option
                  key={item.id}
                  value={item.businessName}
                  disabled={clients.length === 0}
                >
                  {item.businessName}
                </option>
              ))}
            </Select>

            <Pagination
              total={total}
              page={page}
              itemsPerPage={REGISTERS_LIMIT}
              handleChangePage={handleChangePage}
              width="full"
              justifyContent="flex-end"
              sx={{
                "@media (max-width: 600px)": {
                  marginBottom: 15,
                },
              }}
              disabled={loading}
            />
          </Box>

          {getReportError && (
            <Alert status="error">
              <AlertIcon />
              {getReportError}
            </Alert>
          )}
        </Box>

        {getClientsError && (
          <Alert status="error" mt="0.5em">
            <AlertIcon />
            {getClientsError}
          </Alert>
        )}

        {informe.length !== 0 && !loading && (
          <ReportsCard count={total} isLoading={loading} report={informe} />
        )}

        {informe.length === 0 && !loading && (
          <Box textAlign="center" py={10} px={6}>
            <InfoIcon boxSize={"50px"} color={"blue.500"} />
            <Heading as="h2" size="xl" mt={6} mb={2}>
              No hay informes disponible
            </Heading>
          </Box>
        )}

        <Flex justifyItems={"center"}></Flex>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} size={"2xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Nuevo Informe</ModalHeader>
          <ModalCloseButton />
          <NewMessage onClose={onClose} getSendReport={getSendReport} />
        </ModalContent>
      </Modal>
    </>
  );
};

export default SendRepost;
