import {
  Box,
  Heading,
  useColorModeValue,
  Alert,
  AlertIcon,
  Spinner,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Tabs, TabList, Tab } from "@chakra-ui/react";
import Pagination from "../components/Pagination";
import { useAuth } from "../hooks/useAuth";
import { InfoIcon } from "@chakra-ui/icons";
import { fetchAxios, formatHandledErrors } from "../axiosConfig";
import RequestCard from "../components/RequestCard";

export interface FormNewLicenseValues {
  idsubClient: string;
  idlicense: number;
}

const REGISTERS_LIMIT = 10;

const RequestList = () => {
  const { authState } = useAuth();

  const [page, setPage] = useState(0);
  const [filterRequest, setFilterRequest] = useState("all");
  const handleChangePage = (numberPage: number) => setPage(numberPage);

  const [loading, setIsLoading] = useState(true);
  const [request, setrequest] = useState<any>([]);
  const [total, setTotal] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");

  const handleGetRequest = async () => {
    setIsLoading(true);

    try {
      const role = authState.user?.clientType === "Bartik" ? "admin" : "user";
      const uri = `/request/get-request/${authState.user?.id}?page=${page}&limit=${REGISTERS_LIMIT}&role=${role}&statusRequest=${filterRequest}&type=Licencia`;

      const axiosResponse = await fetchAxios(uri, {
        method: "GET",
        headers: { authorization: `Bearer ${authState.token}` },
      });

      const { count, results } = axiosResponse.data.dataInfo;

      setrequest(results);
      setTotal(count);

      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    } catch (error: any) {
      setIsLoading(false);
      const formatedError = formatHandledErrors(error);
      setErrorMsg(formatedError);
    }
  };

  useEffect(() => {
    handleGetRequest();
  }, [filterRequest, page]);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        bg={useColorModeValue("white", "gray.700")}
        padding="1em"
        borderRadius="10px"
      >
        <Heading>Solicitudes de licencias</Heading>
      </Box>
      <Box></Box>

      <Box
        bg={useColorModeValue("white", "gray.700")}
        padding="1em"
        borderRadius="10px"
        mt="1em"
      >
        <Box width="full" display="flex" flexDirection="column">
          <Box
            display="flex"
            justifyItems="center"
            width="full"
            position="relative"
            mb="1em"
            sx={{
              "@media (max-width: 600px)": {
                display: "inline-block",
              },
            }}
          >
            <Box
              height="full"
              position="absolute"
              top="0"
              left="10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            ></Box>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="left"
              width="90%"
              bg={useColorModeValue("white", "gray.700")}
              borderRadius="10px"
              sx={{
                "@media (max-width: 600px)": {
                  marginBottom: 15,
                },
              }}
            >
              <Tabs variant="soft-rounded" colorScheme="green">
                <TabList>
                  <Tab onClick={() => setFilterRequest("all")} fontSize={12}>
                    Bandeja de entrada
                  </Tab>
                  <Tab
                    onClick={() => setFilterRequest("Aprobada")}
                    marginLeft={5}
                    fontSize={12}
                  >
                    Solicitades aprobadas!
                  </Tab>
                  <Tab
                    onClick={() => setFilterRequest("Rechazada")}
                    marginLeft={5}
                    fontSize={12}
                  >
                    Solicitades rechazadas!
                  </Tab>
                </TabList>
              </Tabs>
            </Box>

            <Pagination
              total={total}
              page={page}
              itemsPerPage={REGISTERS_LIMIT}
              handleChangePage={handleChangePage}
              width="full"
              justifyContent="flex-end"
              disabled={loading}
            />
          </Box>

          {loading && (
            <>
              <Spinner mr="0.5em" /> Cargando
            </>
          )}

          {errorMsg && (
            <Alert status="error">
              <AlertIcon />
              {errorMsg}
            </Alert>
          )}

          {request.length !== 0 && !loading && (
            <RequestCard
              count={total}
              // updateCount={updateCount}
              request={request}
            />
          )}

          {request.length === 0 && !loading && (
            <Box textAlign="center" py={10} px={6}>
              <InfoIcon boxSize={"50px"} color={"blue.500"} />
              <Heading as="h2" size="xl" mt={6} mb={2}>
                No hay solicitudes disponible
              </Heading>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default RequestList;
