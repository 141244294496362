import { useState, useEffect } from "react";

import {
  Box,
  Heading,
  Button,
  useColorModeValue,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Spinner,
  Alert,
  AlertIcon,
  useToast,
} from "@chakra-ui/react";

import { FiPlus } from "react-icons/fi";
import ModalFormNewClient, {
  FormNewClientValues,
} from "../components/license/ModalFormNewClient";
import ModalFormNewLicense, {
  FormNewLicenseValues,
} from "../components/license/ModalFormNewLicense";
import ClientCardAdmin from "../components/license/ClientCardAdmin";
import ClientCard from "../components/license/ClientCard";
import { useAuth } from "../hooks/useAuth";
import { fetchAxios, formatHandledErrors } from "../axiosConfig";
import Pagination from "../components/Pagination";
import ModalFormRequestLicense from "../components/license/ModalFormRequestLicense";

const ROWS_LIMIT = 5;

const Licenses = () => {
  const toast = useToast();
  const { authState } = useAuth();

  const { onOpen, onClose, isOpen } = useDisclosure();

  const [modalOptions, setModalOptions] = useState({
    title: "",
    formName: "new_client",
    size: "md",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isLoadingClients, setIsLoadingClients] = useState(true);
  const [countClients, setCountClients] = useState(0);
  const [dataClients, setDataClients] = useState<any>([]);
  const [errorGettingClients, setErrorGettingClients] = useState<string | null>(
    null
  );

  const [page, setPage] = useState(0);

  const handleOpenModal = (formName: string, size: string = "md") => {
    let title = "";

    switch (formName) {
      case "new_client":
        title = "Registrar nuevo cliente";
        break;
      case "new_license":
        title = "Generar nueva licencia";
        break;
      case "get_license":
        title = "Solicitar licencia";
        break;
      default:
        break;
    }

    setModalOptions((prevState) => ({
      ...prevState,
      title,
      formName,
      size,
    }));

    onOpen();
  };

  const handleSendCreateClient = async (values: FormNewClientValues) => {
    setIsSubmitting(true);

    try {
      const uri = `/client/register_new_client`;
      const axiosResponse = await fetchAxios(uri, {
        method: "PUT",
        headers: { authorization: `Bearer ${authState.token}` },
        data: values,
      });

      const { message, userId, type } = axiosResponse.data.dataInfo;

      const newUser = {
        id: userId,
        nit: values.nit,
        businessName: values.businessName,
        email: values.email,
        image: null,
        status: 1,
        licenses: [],
      };

      if (type === "success") {
        handleAddNewClients(newUser);
      }

      toast({
        title: type === "warning" ? "Atención" : "Registro creado",
        description: message,
        status: type,
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      setIsSubmitting(false);
      onClose();
    } catch (error: any) {
      const formatedError = formatHandledErrors(error);

      toast({
        title: "Error",
        description: error.response.data?.dataInfo?.message
          ? error.response.data?.dataInfo?.message
          : formatedError,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      setIsSubmitting(false);
    }
  };

  const handleSendCreateLicense = async (values: FormNewLicenseValues) => {
    setIsSubmitting(true);

    try {
      const uri = `/client/register_new_licenses/${authState.user?.id}`;
      const axiosResponse = await fetchAxios(uri, {
        method: "POST",
        headers: { authorization: `Bearer ${authState.token}` },
        data: values,
      });

      const { message, type, keysOmited } = axiosResponse.data.dataInfo;

      toast({
        title:
          type === "warning" ? "Ocurrio un problema" : "Licencias generadas",
        description: message,
        status: type,
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      setIsSubmitting(false);

      if (keysOmited !== values.keysCount) onClose();
      getClientLicenses();
    } catch (error: any) {
      const formatedError = formatHandledErrors(error);

      toast({
        title: "Error",
        description: error.response.data?.dataInfo?.message
          ? error.response.data.dataInfo.message
          : formatedError,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      setIsSubmitting(false);
    }
  };

  const getClientLicenses = async () => {
    setIsLoadingClients(true);

    try {
      const role = authState.user?.clientType === "Bartik" ? "admin" : "user";
      const uri = `/client/get_client_licenses/${authState.user?.id}?page=${page}&limit=${ROWS_LIMIT}&role=${role}`;

      const axiosResponse = await fetchAxios(uri, {
        headers: { authorization: `Bearer ${authState.token}` },
      });

      const { count, rows } = axiosResponse.data.dataInfo;

      setCountClients(count);
      setDataClients(rows);
    } catch (error: any) {
      const formatedError = formatHandledErrors(error);
      setErrorGettingClients(formatedError);
    } finally {
      setIsLoadingClients(false);
    }
  };

  const handleChangePage = (page: number) => setPage(page);

  const updateEmailClient = (id: number, newEmail: string) => {
    const newState = dataClients.map((item: any) => {
      if (item.id === id) {
        return {
          ...item,
          email: newEmail,
        };
      }

      return item;
    });

    setDataClients(newState);
  };

  const handleAddNewClients = (newUser: any) => {
    setDataClients((prevState: any) => [newUser, ...prevState]);
  };

  const handleUpdateUserStatus = (numberId: string, newStatus: number) => {
    setDataClients((prevState: any) =>
      prevState.map((item: any) => {
        if (item.nit === numberId) {
          return {
            ...item,
            status: newStatus,
          };
        }

        return item;
      })
    );
  };

  useEffect(() => {
    getClientLicenses();
  }, [page]);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        bg={useColorModeValue("white", "gray.700")}
        padding="1em"
        borderRadius="10px"
        width="full"
        sx={{
          "@media (max-width: 600px)": {
            flexDir: "column",
          },
        }}
      >
        <Heading>
          {authState.user?.clientType === "Bartik"
            ? "Clientes y Licencias"
            : "Licencias"}
        </Heading>

        {authState.user?.clientType === "Bartik" && (
          <>
            <Button
              ml="auto"
              mr="1em"
              display="inline-block"
              position="inherit"
              colorScheme="greenDark"
              leftIcon={<FiPlus />}
              whiteSpace="normal"
              sx={{
                "@media (max-width: 600px)": {
                  margin: "0",
                  marginTop: 15,
                  width: "175.5px",
                },
              }}
              onClick={() => handleOpenModal("new_client", "5xl")}
            >
              Nuevo Cliente
            </Button>
            <Button
              colorScheme="darkBlue"
              leftIcon={<FiPlus />}
              display="inline-block"
              position="inherit"
              whiteSpace="normal"
              sx={{
                "@media (max-width: 600px)": {
                  marginTop: 15,
                },
              }}
              onClick={() => handleOpenModal("new_license")}
            >
              Generar Licencia
            </Button>
          </>
        )}

        {authState.user?.clientType !== "Bartik" && (
          <Button
            position="inherit"
            colorScheme="darkBlue"
            ml="auto"
            leftIcon={<FiPlus />}
            onClick={() => handleOpenModal("get_license", "5xl")}
            sx={{
              "@media (max-width: 900px)": {
                marginLeft: 0,
                marginTop: 5,
              },
            }}
          >
            Solicitar Licencia
          </Button>
        )}
      </Box>

      <Box
        bg={useColorModeValue("white", "gray.700")}
        padding="1em"
        borderRadius="10px"
        mt="1em"
      >
        <Box width="full" position="relative" mb="1em">
          {isLoadingClients && (
            <Box
              height="full"
              position="absolute"
              top="0"
              left="10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Spinner mr="0.5em" />
              Cargando...
            </Box>
          )}

          <Pagination
            total={countClients}
            page={page}
            itemsPerPage={ROWS_LIMIT}
            handleChangePage={handleChangePage}
            width="full"
            justifyContent="flex-end"
            disabled={isLoadingClients}
          />
        </Box>

        {errorGettingClients && !isLoadingClients && (
          <Alert status="error">
            <AlertIcon />
            {errorGettingClients}
          </Alert>
        )}

        {!isLoadingClients &&
        authState &&
        authState.user?.clientType === "Bartik" ? (
          dataClients.map((client: any, index: any) => (
            <ClientCardAdmin
              key={index}
              id={client.id}
              name={client.businessName}
              nit={client.nit}
              email={client.email}
              image={client.imageUrl}
              licenses={client.licenses}
              status={client.status}
              updateStatus={handleUpdateUserStatus}
              updateEmailClient={updateEmailClient}
            />
          ))
        ) : (
          <Box
            width="full"
            display="flex"
            flexWrap="wrap"
            justifyContent="space-between"
            sx={{
              "@media (max-width: 900px)": {
                flexDir: "column",
              },
            }}
          >
            {!isLoadingClients &&
              dataClients.map((license: any, index: any) => (
                <ClientCard
                  key={index}
                  id={license.idlicenses}
                  appName={license.appName}
                  customName={license.customName}
                  expirationDate={license.expirationDate}
                  keyLicense={license.keyLicense}
                  operatingSystem={license.operatingSystem}
                  statusKey={license.statusKey}
                  version={license.version}
                  pcid={license.resultspcid}
                  phone={license.phone}
                />
              ))}
          </Box>
        )}
      </Box>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnEsc={!isSubmitting}
        closeOnOverlayClick={!isSubmitting}
        size={modalOptions.size}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalOptions.title}</ModalHeader>
          <ModalCloseButton />

          {modalOptions.formName === "new_client" && (
            <ModalFormNewClient
              handleSubmitForm={handleSendCreateClient}
              onClose={onClose}
            />
          )}

          {modalOptions.formName === "new_license" && (
            <ModalFormNewLicense
              handleSubmitForm={handleSendCreateLicense}
              onClose={onClose}
            />
          )}

          {modalOptions.formName === "get_license" && (
            <ModalFormRequestLicense onClose={onClose} />
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default Licenses;
